import React from "react";
import {Stack} from "react-bootstrap";
import {ConectForm} from "../Components/ConectForm";
import {ChatsContentainer} from "../Components/ChatsContentainer";
import {NewMessages} from "../Components/NewMessages";
import {hasSocket, initiateSocket, MessageData, setSelectedChatID} from "../Config/socket";
import {User} from "../Actions/Domain";
import {AlertMessages} from "../Components/AlertMessages";
import {Channel, Message} from "../Actions/Chat/ChatDomain";

export const IndexPage : React.FunctionComponent = (_props) => {
    const [user, setUser] = React.useState<User|undefined>(undefined);
    const [token, setToken] = React.useState<string>('');
    const [isValidToken, setIsValidToken] = React.useState<boolean>(false);
    const [serverMessage, setServerMessage] = React.useState<string|undefined>(undefined);

    const [conectedUsers, setConectedUsers] = React.useState<User[]>([]);
    const [toastMessage, setToastMessage] = React.useState<MessageData[]>([]);

    const [selectedChat, setSelectedChat] = React.useState<Channel|undefined>(undefined);

    const [messages, setMessages] = React.useState<Message[]>([]);
    const [canales, setCanales] = React.useState<Channel[]>([]);

    const [updateChannels, setUpdateChannels] = React.useState<boolean>(false);

    const handleSocketRecivedMessage = React.useCallback( (msg: MessageData, chatID:number) => {
        console.log('Message received:', msg.EvtType, msg.Message);
        switch (msg.EvtType) {
            case 'message':
                const message = msg.Message as Message;
                if (message.chat_id === chatID){
                    setMessages( prevState => [message, ...prevState]);
                }else{
                    if (msg.User?.usuario_id !== user?.usuario_id){
                        setToastMessage( prevState => [...prevState, msg] );
                    }
                }

                break
            case 'join':
                if (msg.User?.usuario_id !== user?.usuario_id){
                    msg.Message = `${msg.User?.clave} se ha unido al chat`;
                    setToastMessage( prevState => [...prevState, msg] );
                }
                break;
            case 'leave':
                if (msg.User?.usuario_id !== user?.usuario_id){
                    msg.Message = `${msg.User?.clave} ha dejado el chat`;
                    setToastMessage( prevState => [...prevState, msg] );
                }
                break;
        }
    }, [selectedChat?.chat_id, user?.usuario_id])

    React.useEffect(() => {
        if( token === "" || !isValidToken || user === undefined) {
            setConectedUsers([])
            return;
        }
        if (!hasSocket()){
            initiateSocket(token, handleSocketRecivedMessage, setConectedUsers, setUpdateChannels);
            setUpdateChannels(true);
        }
    },[token, isValidToken, user])

    React.useEffect(()=>{
        const selectedChatId = ( selectedChat === undefined) ? 0 : selectedChat.chat_id;
        setSelectedChatID(selectedChatId);
    },[selectedChat])


    return (
        <React.Fragment>
            <AlertMessages
                toastMessage={toastMessage}
                setToastMessage={setToastMessage}
                canales={canales}
                setSelectedChat={setSelectedChat}
            />
        <div style={{height:'100vh'}}>
            <Stack gap={3} style={{height:'100%', backgroundColor:"#aaa"}}>
                <div className="p-2">
                    <ConectForm token={token}
                                setToken={setToken}
                                isValidToken={isValidToken}
                                setIsValidToken={setIsValidToken}

                                setUser={setUser}
                    />
                </div>
                <div className="p-2 mb-auto">
                    <ChatsContentainer token={ token }
                                       isValidToken={ isValidToken }
                                       serverMessage ={serverMessage}
                                       conectedUsers={conectedUsers}
                                       selectedChat={selectedChat}
                                       setSelectedChat={setSelectedChat}
                                       messages={messages}
                                       setMessages={setMessages}
                                       setCanales={setCanales}
                                       updateChannels={updateChannels}
                                       setUpdateChannels={setUpdateChannels}
                                       setToastMessage={setToastMessage}
                    />
                </div>
                <div className="p-2">
                    <NewMessages user={user}
                                 selectedChat={selectedChat}
                                 token={token}
                    />
                </div>

            </Stack>
        </div>
        </React.Fragment>
    )
}

import React from "react";
import {Badge, Button, ButtonGroup, ListGroup, Spinner} from "react-bootstrap";
import {Channel, ChatGroup} from "../Actions/Chat/ChatDomain";
import {useParameterizedQuery} from "react-fetching-library";
import {deleteChannel, getGroups} from "../Actions/Chat/ChatActions";
import {FaTrash, FaPencil} from "react-icons/fa6";
import {MessageData} from "../Config/socket";
import {ModalEditeChannel} from "./ModalEditeChannel";

interface ViewProps {
    token: string;
    isValidToken: boolean;
    selectedChat: Channel|undefined;
    setSelectedChat: React.Dispatch<React.SetStateAction<Channel|undefined>>;
    setCanales: React.Dispatch<React.SetStateAction<Channel[]>>
    updateChannels: boolean;
    setUpdateChannels: React.Dispatch<React.SetStateAction<boolean>>
    setToastMessage: React.Dispatch<React.SetStateAction<MessageData[]>>
    editChannelId: number,
    setEditChannelId: React.Dispatch<React.SetStateAction<number>>
}
export const ChanelsContainer: React.FunctionComponent<ViewProps> = (props) => {
    const {
        token,
        isValidToken,
        setSelectedChat,
        selectedChat,
        setCanales,
        updateChannels,
        setUpdateChannels,
        setToastMessage,
        editChannelId,
        setEditChannelId
    } = props;

    const [groups, setGroups] = React.useState<ChatGroup[]>([]);

    const deleteChannelAction = useParameterizedQuery( deleteChannel );
    const getGroupsAction = useParameterizedQuery( getGroups );

    const handdleSelectChat = React.useCallback((channel: Channel) => {
        setSelectedChat(channel);
    }, [setSelectedChat])

    const handleDeleteChannel = React.useCallback((channel: Channel) => {
        if (deleteChannelAction.loading) {
            return;
        }

        deleteChannelAction.query({token:token, chat_id:channel.chat_id}).then((response) => {
            if (response.payload) {
                const payload = response.payload;

                if (payload.error["has-error"]){
                    const errorMessage = (payload.error.error) ? payload.error.error : 'Error al eliminar el canal';
                    const messageData: MessageData = {EvtType:'error', Message: errorMessage, Timestamp: Date.now()};
setToastMessage( prevState => [...prevState, messageData]);
                }
            }
        });
    }, [deleteChannelAction])

    const ListChanels = React.useMemo(()=>{
        if (groups.length > 0) {
            return groups.map((group, index) => {
                return (
                    <React.Fragment key={ index }  >
                        <h6 style={ {marginTop:'10px'} }>{ group.grupo }</h6>
                        <ListGroup>
                            {
                                group.canales.map((channel, index) => {
                                    return (
                                        <ListGroup.Item
                                            as="li"
                                            className="d-flex justify-content-between align-items-start"
                                            key={channel.chat_id}
                                            onClick={() => handdleSelectChat(channel)}
                                            active={selectedChat?.chat_id === channel.chat_id}
                                        >

                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{channel.nombre}</div>
                            </div>
                            {
                                channel.mensajes_sin_leer > 0 &&
                                <Badge
                                    bg={selectedChat?.chat_id === channel.chat_id ? 'secondary' : 'primary'}
                                    pill
                                >
                                    {channel.mensajes_sin_leer}
                                </Badge>
                            }

                                            <ButtonGroup aria-label="Basic example">
                                                <Button variant="outline-primary"
                                                        size="sm"
                                                        disabled={deleteChannelAction.loading}
                                                        onClick={() => setEditChannelId(channel.chat_id)}
                                                >
                                                    <FaPencil/>
                                                </Button>

                                                <Button variant="outline-danger"
                                                        size="sm"
                                                        disabled={deleteChannelAction.loading}
                                                        onClick={() => handleDeleteChannel(channel)}
                                                >
                                                    <FaTrash/>
                                                </Button>

                                            </ButtonGroup>
                                        </ListGroup.Item>
                                    )
                                })
                            }
                        </ListGroup>
                    </React.Fragment>

                )
            })
        }

        return (
            <ListGroup.Item disabled={true}>Sin elementos</ListGroup.Item>
        )
    }, [groups, handdleSelectChat, selectedChat])

    React.useEffect(()=>{
        if(isValidToken){
            if( updateChannels ) {
                setUpdateChannels(false);
                getGroupsAction.query(token).then((response) => {
                    if (response.payload) {
                        setGroups(response.payload.response);
                    }
                });
            }
        }else{
            setSelectedChat(undefined);
            setGroups([]);
        }

    }, [token, isValidToken, updateChannels, setUpdateChannels])

    React.useEffect(()=>{
        const canales : Channel[] = []

        groups.forEach((group) => {
            group.canales.forEach((channel) => {
                canales.push(channel)
            })
        })

        setCanales(canales);
    },[groups, setCanales])

    React.useEffect(()=>{
        if (selectedChat !== undefined && groups.length > 0){
            const existChannelInGroup = groups.some((group) => {
                return group.canales.some((channel) => {
                    return channel.chat_id === selectedChat.chat_id;
                })
            })

            if (!existChannelInGroup) {
                setSelectedChat(undefined);
            }
        }
    },[groups, selectedChat])

    return (
        <React.Fragment>
            <div style={{height: '580px', overflow: 'scroll'}}>
                <h5>
                    {  getGroupsAction.loading && (
                        <React.Fragment>
                            <Spinner animation="border" size="sm" />{' '}
                        </React.Fragment>
                    ) }
                    Canales
                </h5>
                {   ListChanels  }
            </div>
        </React.Fragment>
    )
}

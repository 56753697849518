import React from "react";
import {Button, Col, Form, Row, Stack} from "react-bootstrap";
import {validateToken} from "../Actions/Chat/ChatActions";
import {useParameterizedQuery} from "react-fetching-library";
import {User} from "../Actions/Domain";
import {disconnectSocket} from "../Config/socket";

interface ViewProps {
    token: string;
    isValidToken: boolean;
    setToken: React.Dispatch<React.SetStateAction<string>>;
    setIsValidToken: React.Dispatch<React.SetStateAction<boolean>>;
    setUser: React.Dispatch<React.SetStateAction<User|undefined>>;
}
export const ConectForm: React.FunctionComponent<ViewProps> = (props) => {
    const [input, setInput] = React.useState<string>('');
    const [isError, setIsError] = React.useState<boolean>(false);
    const [text, setText] = React.useState<string>('');

    const validateLogin = useParameterizedQuery( validateToken );

    const handdleValidateLogin = React.useCallback(() => {
        if (input.length > 0) {
            validateLogin.query(input).then((response) => {
                if(response.payload){
                    console.log(response.payload.error["has-error"])
                    if (response.payload.error["has-error"]) {
                        props.setToken('');
                        props.setIsValidToken(false);

                        if( response.payload.error.error ){
                            setText(response.payload.error.error);
                        }
                        return;
                    }else{
                        props.setToken(response.payload.response.token);
                        props.setIsValidToken(true);

                        const user = response.payload.response.user;
                        props.setUser(user);

                        localStorage.setItem('token', response.payload.response.token);
                        setText(`Bienvenido ${user.clave}`);
                    }
                }
            });
        }
    }, [input, props, validateLogin])

    const handdleReset = React.useCallback(() => {
        props.setToken('');
        props.setIsValidToken(false);
        props.setUser(undefined);

        disconnectSocket();

        const resetToken = localStorage.getItem('token')
        if( resetToken ){
            setInput(resetToken);
        }else{
            setInput('')
        }
        setText('');
    }, [props])


    React.useEffect(() => {
        const resetToken = localStorage.getItem('token')
        if( resetToken ){
            setInput(resetToken);
        }
    }, [])

    return (
        <React.Fragment>
            <Stack direction={'horizontal'} gap={3}>
                <Form.Control
                    className="me-auto"
                    placeholder="Agregar Token..."
                    value={input}
                    onChange={(e) => {
                        setInput(e.target.value);
                    }}
                />
                <Button variant="secondary"
                        disabled={ props.isValidToken }
                        onClick={() => {
                            handdleValidateLogin();
                        }}
                >
                    Submit
                </Button>
                <div className="vr" />
                <Button variant="outline-danger"
                        disabled={ !props.isValidToken }
                        onClick={() => {
                            handdleReset();
                        }}
                >
                    Reset
                </Button>

            </Stack>
            { text.length > 0 ? <p className={ isError ? 'text-danger' : 'text-default' }>{text}</p> : null}
        </React.Fragment>
    )
}

import React from "react";
import {User} from "../Actions/Domain";
import {ModalNewChannel} from "./ModalNewChannel";
import {getChannel} from "../Actions/Chat/ChatActions";
import {useParameterizedQuery} from "react-fetching-library";

interface ViewProps {
    token: string;
    isValidToken: boolean;
    editChannelId: number;
    setEditChannelId: React.Dispatch<React.SetStateAction<number>>;

    users: User[];
}

export const ModalEditeChannel: React.FunctionComponent<ViewProps> = (props) => {
    const {
        token,
        isValidToken,
        editChannelId,
        setEditChannelId,
        users,
    } = props;

    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [selectedUsers, setSelectedUsers] = React.useState<number[]>([])
    const [channelName, setChannelName] = React.useState<string>('')

    const getChannelAction = useParameterizedQuery( getChannel );

    React.useEffect(()=>{
        console.log('editChannelId', editChannelId)
        if( editChannelId > 0 ){
            if ( getChannelAction.loading ) return;

            getChannelAction.query({token:token, chat_id:editChannelId}).then((response) => {
                    if (response.payload) {
                        const payload = response.payload;
                        if (!payload.error["has-error"]) {
                            const chatData = payload.response;
                            setSelectedUsers(chatData.destinatarios)
                            setChannelName(chatData.canal.nombre)
                        }
                    }
                })
        }
        setOpenModal( editChannelId > 0 )
    }, [editChannelId, token])

    React.useEffect(()=>{
        if( !openModal ){
            setEditChannelId(0)
            setSelectedUsers([])
            setChannelName('')
        }
    }, [openModal, setEditChannelId])

    if( editChannelId === 0) {
        return null
    }

    return (
        <ModalNewChannel
            createChannelAction={ ()=>{ alert('HOla mundo') } }
            users={users}
            selectedUsers={selectedUsers}
            openModal={openModal}
            setOpenModal={setOpenModal}
            setSelectedUsers={setSelectedUsers}
            loadingInfo={getChannelAction.loading}
            channelName={channelName}
        />
    )
}

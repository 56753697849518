import React from "react";
import {IndexPage} from "../Pages/Index";


export interface RoutesType {
    path: string;
    component: React.ComponentType;
    isPrivate: boolean;
    children?: {
        path: string;
        component: React.ComponentType;
        isPrivate:boolean
    }[];
}

export const routes:RoutesType[] = [
    {
        path:'/',
        component:IndexPage,
        isPrivate: false,
        children:[]
    },
]

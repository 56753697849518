import React from "react";
import {Channel, Message} from "../Actions/Chat/ChatDomain";
import {getMessages} from "../Actions/Chat/ChatActions";
import {useParameterizedQuery} from "react-fetching-library";
import {Col, Row, Spinner} from "react-bootstrap";
import {ArchivosDeMensajes} from "./ArchivosDeMensajes";

interface ViewProps {
    token: string;
    isValidToken: boolean;
    selectedChat: Channel|undefined;
    serverMessage?: string;
    messages: Message[];
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
}
export const MessajesContainer: React.FunctionComponent<ViewProps> = (props) => {
    const {
        token,
        isValidToken,
        selectedChat,
        serverMessage,
        messages,
        setMessages
    } = props;

    // @ts-ignore
    const getMessagesAction = useParameterizedQuery( getMessages );

    const Header = React.useMemo(()=>{
        if ( token.length > 0 && isValidToken && selectedChat) {
            return `Chat: ${selectedChat.nombre}`
        }
        return 'Mensajes...'
    }, [isValidToken, selectedChat, token])

    const handleGetMessages = React.useCallback((tokenStr: string, page:number)=>{
        if(selectedChat){
            getMessagesAction.query({
                token:tokenStr,
                chat_id:selectedChat.chat_id,
                page:page,
                limit:10
            } )
                .then( ({payload}) => {
                    if (payload) {
                        if(!payload.error["has-error"]){
                            setMessages( prevState => [...prevState, ...payload.response.rows] )

                            if(payload.response.total > page * 10){
                                handleGetMessages(tokenStr, page + 1)
                            }
                        }
                    }
                })
        }

    }, [getMessagesAction, selectedChat])

    React.useEffect(()=>{
        setMessages([]);
        if ( token.length > 0 && isValidToken && selectedChat) {
            handleGetMessages(token, 1)
        }
    }, [isValidToken, selectedChat, token])

    return (
        <div>
            <h5>
                {  getMessagesAction.loading && (
                    <React.Fragment>
                        <Spinner animation="border" size="sm" />{' '}
                    </React.Fragment>
                ) }
                {Header}
            </h5>
            <div className="d-flex flex-column-reverse bd-highlight" style={{backgroundColor: 'white', height: '580px', overflow:'scroll'}}>
                {
                    serverMessage && (
                        <div className="p-2 bd-highlight"
                             style={{fontStyle: 'italic', color: '#676666', textAlign: 'center'}}>
                            Mensajes del servidor
                        </div>
                    )
                }


                {messages.map((msg) => {
                    return (
                        <Row key={msg.id} className="p-2 bd-highlight">
                            <Col sm={2}>
                                <div style={{fontWeight:'bolder'}}>
                                {msg.usuario.clave} Dice:
                                </div>
                            </Col>
                            <Col sm={10}>
                                {msg.mensaje}
                                <ArchivosDeMensajes archivos={msg.archivos}/>
                            </Col>
                        </Row>
                    )
                })}

                { getMessagesAction.loading ?
                    <div className="p-2 bd-highlight" style={{textAlign: "center"}}>
                        <Spinner animation="border" />
                    </div> : null
                }
            </div>
        </div>
    )
}

import React from 'react';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import {Navigate, Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import {routes} from "./Config/routes";
import {ClientContextProvider} from "react-fetching-library";
import {Client} from "./Config/Client";

const AppRoutes:React.FunctionComponent = (_props) => {
  return (
      <Router>
        <Routes>
          { routes.map( (props ) =>
              <Route
                  key={props.path}
                  path={props.path}
                  element={( !props.isPrivate  ) ? <props.component /> : <Navigate to="/" replace />}
              >
                {props.children ? props.children.map(( el ) => {
                  return(
                      <Route
                          key={el.path}
                          path={el.path}
                          element={(!el.isPrivate ) ? <el.component /> : <Navigate to="/" replace />} />
                  )
                }) : <></> }
              </Route>
          ) }
        </Routes>
      </Router>
  )
}

function App() {
  return (

          <ClientContextProvider client={Client}>
              <AppRoutes />
          </ClientContextProvider>

  );
}

export default App;

import React from "react";
import {useMutation, useParameterizedQuery} from "react-fetching-library";
import {createChannel, getUsers} from "../Actions/Chat/ChatActions";
import {User} from "../Actions/Domain";
import {Button, ListGroup, Spinner} from "react-bootstrap";
import {Channel} from "../Actions/Chat/ChatDomain";
import {ModalNewChannel} from "./ModalNewChannel";
import {ModalEditeChannel} from "./ModalEditeChannel";

interface ViewProps {
    token: string;
    isValidToken: boolean;
    conectedUsers: User[];
    setSelectedChat: React.Dispatch<React.SetStateAction<Channel|undefined>>;
    editChannelId: number;
    setEditChannelId: React.Dispatch<React.SetStateAction<number>>;
}

const LIMIT = 10;
export const UsersContainer: React.FunctionComponent<ViewProps> = (props) => {
    const {
        token,
        isValidToken,
        conectedUsers,
        setSelectedChat,
        editChannelId,
        setEditChannelId
    } = props;

    const [users, setUsers] = React.useState<User[]>([]);
    const [selectedUsers, setSelectedUsers] = React.useState<number[]>([])

    const getUsersAction = useParameterizedQuery( getUsers );
    const addChannelAction = useMutation( createChannel );

    const [openModal, setOpenModal] = React.useState<boolean>(false);

    const orderUsers = React.useMemo(()=>{
        if (!isValidToken) return [];

        const conectedUsersIds = conectedUsers.map( user => user.usuario_id );
        const usersNotConected = users.filter( user => !conectedUsersIds.includes(user.usuario_id) );
        const conected = conectedUsers.map( user => { return {...user, is_active:true} });

        return [...conected, ...usersNotConected]
    }, [users, conectedUsers, isValidToken])

    const handleGetUsers = React.useCallback((tokenStr: string, page:number)=>{
        if ( getUsersAction.loading ) return;

        getUsersAction.query({
            token:tokenStr,
            page:page,
            limit:LIMIT
        } )
            .then( ({payload}) => {
                if (payload) {
                    if(!payload.error["has-error"]){
                        setUsers( prevState => [...prevState, ...payload.response.rows] )

                        if(payload.response.total > page * LIMIT){
                            handleGetUsers(tokenStr, page + 1)
                        }
                    }
                }
            })

    }, [getUsersAction])

    const createNewChannel = React.useCallback((name:string|undefined) => {
        if (name === undefined) name = `Chat ${selectedUsers.join('-')}`
        addChannelAction.mutate({
            token: token,
            user_id: selectedUsers,
            chat_name: name
        })
            .then( ({payload}) => {
                if (payload) {
                    if(!payload.error["has-error"]){
                        setSelectedChat(payload.response)
                        setSelectedUsers([])
                    }
                }
            })
    }, [addChannelAction, selectedUsers, setSelectedChat, token])
    const doubleClickUser = React.useCallback(() => {
        if (addChannelAction.loading) return;

        if (selectedUsers.length > 1) {
            setOpenModal(true)
            return;
        }

        createNewChannel(undefined)
    }, [addChannelAction.loading, createNewChannel, selectedUsers.length])

    const handleSelectUser = React.useCallback((user: User) => {
        if (selectedUsers.includes(user.usuario_id)){
            setSelectedUsers( prevState => prevState.filter( id => id !== user.usuario_id) )
        }else{
            setSelectedUsers( prevState => [...prevState, user.usuario_id] )
        }
    }, [selectedUsers])

    React.useEffect(()=>{
        setUsers([]);
        if ( token.length > 0 && isValidToken) {
            handleGetUsers(token, 1)
        }
    }, [isValidToken, token])

    return (
        <React.Fragment>
            <ModalEditeChannel
                editChannelId={editChannelId}
                setEditChannelId={setEditChannelId}
                token={token}
                isValidToken={isValidToken}
                users={orderUsers}

            />

            { selectedUsers.length > 1 && (
                <ModalNewChannel
                    createChannelAction={createNewChannel}
                    users={orderUsers}
                    selectedUsers={selectedUsers}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    setSelectedUsers={setSelectedUsers}
                />
            )}

            <div style={{height: '580px', overflow: 'scroll'}}>
                <h5 key={`users_congtainer_of_${token}`}>

                    <Button
                        onClick={ e => {
                            e.preventDefault();
                            doubleClickUser()
                        } }
                        variant="primary"
                        size="sm"
                        disabled={ selectedUsers.length === 0 || addChannelAction.loading || getUsersAction.loading || !isValidToken }
                    >
                        NEW
                    </Button>
                    {' '}
                    {  (getUsersAction.loading || addChannelAction.loading) && (
                        <React.Fragment>
                            <Spinner animation="border" size="sm" />{' '}
                        </React.Fragment>
                    ) }
                    Usuarios
                </h5>
                <ListGroup key={`users_of_${token}`} >
                    {
                        orderUsers.map((user, index) => {
                            const cursor = (addChannelAction.loading) ? 'progress' : 'pointer';
                            return (
                                <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-center"
                                    key={user.usuario_id}
                                    onClick={() => {
                                        handleSelectUser(user)
                                    }}
                                    style={{cursor:cursor}}
                                    active={selectedUsers.includes(user.usuario_id)}
                                >
                                    {
                                        user.is_active &&
                                        <span style={{fontSize:5}} className="badge bg-success rounded-pill">&nbsp;</span>
                                    }
                                    {
                                        !user.is_active &&
                                        <span style={{fontSize:5}} className="badge bg-danger rounded-pill">&nbsp;</span>
                                    }

                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{user.clave}</div>
                                        {user.nombres} {user.apellidos}
                                    </div>


                                </ListGroup.Item>
                            )
                        })
                    }
                </ListGroup>
            </div>
        </React.Fragment>
    )
}

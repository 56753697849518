import React from "react";
import {MessageData} from "../Config/socket";
import {Button, Toast, ToastContainer} from "react-bootstrap";
import Moment from "react-moment";
import {Channel, Message} from "../Actions/Chat/ChatDomain";

interface ViewProps {
    toastMessage: MessageData[];
    setToastMessage: React.Dispatch<React.SetStateAction<MessageData[]>>;
    canales: Channel[];
    setSelectedChat: React.Dispatch<React.SetStateAction<Channel|undefined>>;
}


export const AlertMessages: React.FunctionComponent<ViewProps> = (props) => {
    const {
        toastMessage,
        setToastMessage,
        canales,
        setSelectedChat
    } = props;
    const [count, setCount] = React.useState<number>(0);

    const closeToastHanddle = React.useCallback((index: number) => {
        setToastMessage(prevState => prevState.filter((_, i) => i !== index))
    }, [setToastMessage])

    const ToastsElements = React.useMemo(()=>{
        if (count === 0) return null;

        return toastMessage.map((msg, index) => {
            const date = new Date(msg.Timestamp * 1000);

            let Header = null;
            let variant = '';
            let className = undefined;
            let content = null;

            switch (msg.EvtType) {
                case 'join':
                    if (msg.User === undefined){
                        return null;
                    }

                    variant = 'success';
                    className = 'text-white';
                    Header = (
                        <Toast.Header>
                            <strong className="me-auto">Nuevo usuario agregado...</strong>
                            <Moment date={date} ago fromNow />
                        </Toast.Header>
                    );
                    content = (
                        <React.Fragment>
                            <strong>{ msg.User.clave }</strong>{': '}
                            Se ha unido al chat
                        </React.Fragment>
                    );
                    break;
                case 'leave':
                    if (msg.User === undefined){
                        return null;
                    }

                    variant = 'danger';
                    className = 'text-white';
                    Header = (
                        <Toast.Header>
                            <strong className="me-auto">Usuario desconectado</strong>
                            <Moment date={date} ago fromNow />
                        </Toast.Header>
                    );
                    content = (
                        <React.Fragment>
                            <strong>{ msg.User.clave }</strong>{': '}
                            Ha dejado el chat
                        </React.Fragment>
                    );
                    break;

                case 'message':
                    if (msg.User === undefined){
                        return null;
                    }

                    variant = 'info';
                    Header = (
                        <Toast.Header>
                            <strong className="me-auto">
                                <strong>{msg.User.clave} Dice</strong>{': '}
                            </strong>
                            <Moment date={date} ago fromNow />
                        </Toast.Header>
                    );

                    const msgContent = msg.Message as Message;

                    const channel = canales.find((channel) => channel.chat_id === msgContent.chat_id);

                    content = (
                        <React.Fragment>
                            <Toast.Body className={className}>
                                {msgContent.mensaje} <br /><br />
                                en el canal: {' '}
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a style={ {fontWeight: "bold", textDecoration:'underline', fontStyle:'italic', cursor:'pointer'} }
                                   href={'#'}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if(channel){
                                            closeToastHanddle(index);
                                            setSelectedChat(channel);
                                        }else{
                                            alert('Canal no encontrado...')
                                        }
                                      }}
                                >
                                    { channel && channel.nombre }
                                </a>
                            </Toast.Body>
                        </React.Fragment>
                    );
                    break;
                case 'error':
                    if (typeof msg.Message === 'string'){
                        variant = 'danger';
                        className = 'text-white';
                        Header = (
                            <Toast.Header>
                                <strong className="me-auto">Error</strong>
                                <Moment date={date} ago fromNow />
                            </Toast.Header>
                        );


                        content = (
                            <React.Fragment>
                                { msg.Message }
                            </React.Fragment>
                        );
                    }
            }


            //console.log(date, msg.Timestamp)
            return <Toast key={index}
                          onClose={() => {
                                closeToastHanddle(index);
                          }}
                          bg={variant}
            >
                { Header }
                <Toast.Body className={className}>{ content }</Toast.Body>

            </Toast>
        })
    }, [toastMessage, setToastMessage, count, canales, setSelectedChat])

    React.useEffect(()=>{
        if (toastMessage.length === 0){
            setCount(0);
        }else{
            setCount(1)
            setTimeout(() => {
                setCount(prevState => prevState + 1);
            }, 10000);
        }
    }, [count, toastMessage.length])

    if (toastMessage.length === 0)
        return null;

    return  <div
        aria-live="polite"
        aria-atomic="true"
        //className="position-relative"
        style={{ minWidth: '450px', minHeight: '240px', position: 'absolute', top: '10px', right: '10px'}}
    >
        <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
            { ToastsElements }
        </ToastContainer>
    </div>;
}

import React from "react";
import {Button, Col, Form, ListGroup, Modal, Row, Spinner} from "react-bootstrap";
import {User} from "../Actions/Domain";

interface ViewProps {
    createChannelAction: (name: string | undefined) => void;
    users: User[];
    setSelectedUsers: React.Dispatch<React.SetStateAction<number[]>>;
    selectedUsers: number[];
    openModal: boolean;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;

    loadingInfo?: boolean;
    channelName?: string;
}

export const ModalNewChannel: React.FunctionComponent<ViewProps> = (props) => {
    const {
        createChannelAction,
        users,
        selectedUsers,
        openModal,
        setOpenModal,
        setSelectedUsers,
        channelName
    } = props;

    const loadingInfo = props.loadingInfo || false;

    const [chatName, setChatName] = React.useState<string>("");

    const usersInChannel = React.useMemo(()=>{
        const u = users
            .filter( user => selectedUsers.includes(user.usuario_id) )
            .map( user => {
                const large_name = (user.nombres + " " + user.apellidos).trim()
                const name = (large_name.length > 0) ? large_name :  user.clave;

                return (
                    <ListGroup.Item key={user.usuario_id}
                            variant={"primary"}
                            onClick={() => {
                                setSelectedUsers( prevState => prevState.filter( id => id !== user.usuario_id ) )
                            }}
                    >
                        { name }
                    </ListGroup.Item>
                )
            })

        return (
            <React.Fragment>
                <h5>Seleccionados</h5>
                <ListGroup>
                    { u }
                </ListGroup>
            </React.Fragment>
        )
    }, [users, selectedUsers])

    const usersNotInChannel = React.useMemo(()=>{
        const u = users
            .filter( user => !selectedUsers.includes(user.usuario_id) )
            .map( user => {
                const large_name = (user.nombres + " " + user.apellidos).trim()
                const name = (large_name.length > 0) ? large_name :  user.clave;

                return (
                    <ListGroup.Item key={user.usuario_id}
                            variant={"secondary"}
                            onClick={() => {
                                setSelectedUsers( prevState => [...prevState, user.usuario_id] )
                            }}
                    >
                        { name }
                    </ListGroup.Item>
                )
            })

        return (
            <React.Fragment>
                <h5>Otros</h5>
                <ListGroup>
                    { u }
                </ListGroup>
            </React.Fragment>
        )
    }, [users, selectedUsers])

    const handleClose = React.useCallback(() => {
        setOpenModal(false);
    }, [setOpenModal])

    React.useEffect(()=>{
        if( channelName !== undefined ){
            setChatName(channelName)
        }
    }, [channelName])

    return (
        <Modal show={openModal}
               onHide={handleClose}
               backdrop="static"
               keyboard={false}
               size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    {
                        loadingInfo ?
                            <React.Fragment>
                                <Spinner animation="border" />
                                {' '}
                            </React.Fragment>:
                            null
                    }
                    Datos del Canal:
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Es necesario llenar unos datos antes de crear el canal:
                <Form>
                    <Form.Group controlId="channelName">
                        <Form.Label>Nombre del Canal</Form.Label>
                        <Form.Control type="text"
                                      placeholder="Nombre del Canal"
                                      value={chatName}
                                      onChange={e => setChatName(e.target.value)}
                        />

                        <Row style={{marginTop:'15px'}}>
                            <Col>
                                { usersInChannel }
                            </Col>
                            <Col>
                                { usersNotInChannel }
                            </Col>
                        </Row>

                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary"
                        disabled={ chatName.length === 0 }
                        onClick={ e => {
                            e.preventDefault();
                            createChannelAction(chatName)
                        }}
                >
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
};

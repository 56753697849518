import React from "react";
import {ArchivoMessage} from "../Actions/Chat/ChatDomain";

interface ViewProps {
    archivos: ArchivoMessage[]
}

export const ArchivosDeMensajes: React.FunctionComponent<ViewProps> = (props) => {
    const {
        archivos
    } = props;

    const ARCHIVOS_BTN = React.useMemo(()=>{

        const elements = archivos.map( (archivo, index) => {
            return (
                <li>
                    <a key={index} href={archivo.url} target="_blank" rel="noreferrer" >
                        {archivo.nombre_archivo}
                    </a>
                </li>
            )
        });

        return (
            <ul>
                { elements }
            </ul>
        );
    }, [archivos])

    if ( archivos.length === 0 ){
        return null
    }

    return (
        <React.Fragment>
            {ARCHIVOS_BTN}
        </React.Fragment>
    );
}

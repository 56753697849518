import React from "react";
import {Col, Row} from "react-bootstrap";
import {ChanelsContainer} from "./ChanelsContainer";
import {Channel, Message} from "../Actions/Chat/ChatDomain";
import {MessajesContainer} from "./MessajesContainer";
import {ServicesUrl} from "../Config/UrlRuotes";
import {UsersContainer} from "./UsersContainer";
import {User} from "../Actions/Domain";
import {MessageData} from "../Config/socket";

interface ViewProps {
    token: string;
    isValidToken: boolean;
    serverMessage: string | undefined;
    conectedUsers: User[];
    selectedChat: Channel|undefined;
    setSelectedChat: React.Dispatch<React.SetStateAction<Channel|undefined>>;
    messages: Message[];
    setMessages: React.Dispatch<React.SetStateAction<Message[]>>
    setCanales: React.Dispatch<React.SetStateAction<Channel[]>>
    updateChannels: boolean;
    setUpdateChannels: React.Dispatch<React.SetStateAction<boolean>>
    setToastMessage: React.Dispatch<React.SetStateAction<MessageData[]>>
}
export const ChatsContentainer : React.FunctionComponent<ViewProps> = (props) => {
    const {
        token,
        isValidToken,
        serverMessage,
        conectedUsers,
        selectedChat,
        setSelectedChat,
        messages,
        setMessages,
        setCanales,
        updateChannels,
        setUpdateChannels,
        setToastMessage
    } = props;

    const [editChannelId, setEditChannelId] = React.useState<number>(0);


    return (
        <Row>
            <Col sm={3} >
                <ChanelsContainer token={token}
                                  isValidToken={isValidToken}
                                  setSelectedChat={setSelectedChat}
                                  selectedChat={ selectedChat }
                                  setCanales={setCanales}
                                  updateChannels={updateChannels}
                                  setUpdateChannels={setUpdateChannels}
                                  setToastMessage={setToastMessage}
                                  editChannelId={editChannelId}
                                  setEditChannelId={setEditChannelId}
                />
            </Col>

            <Col sm={7}>
                <MessajesContainer token={token}
                                   isValidToken={isValidToken}
                                   selectedChat={ selectedChat }
                                   serverMessage={serverMessage}
                                   messages={messages}
                                      setMessages={setMessages}

                />
            </Col>

            <Col sm={2}>
                <UsersContainer token={token}
                                isValidToken={isValidToken}
                                conectedUsers={conectedUsers}
                                setSelectedChat={setSelectedChat}
                                editChannelId={editChannelId}
                                setEditChannelId={setEditChannelId}
                />
            </Col>
        </Row>
    )
}

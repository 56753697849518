import {Company, User} from "../Actions/Domain";
import {Message} from "../Actions/Chat/ChatDomain";

let socket:any = undefined;
let selectedChatId:number = 0;

export interface MessageData {
    EvtType: 'join' | 'leave' | 'message' | 'error';
    Company?: Company;
    User?: User;
    Timestamp: number;
    Message: string | User[] | Message;
}
export const initiateSocket = (room: string, handleFunction: (msg: any, chatID: number) => void, setConectedUsers:(users: User[])=>void, setUpdateChannels:(flag: boolean)=>void) => {
    // @ts-ignore
    socket = io.connect('/', {
        path: '/ws'
    });
    console.log(`Connecting socket...`);

    socket.connect();
    socket.on('connect', () => {
        socket.emit('join', room);
        console.log('Socket connected!');
    });

    socket.on("connect_error", (err:any) => {
        console.log('--------->Error connecting socket...')
        // the reason of the error, for example "xhr poll error"
        console.log(err.message);

        // some additional description, for example the status code of the initial HTTP response
        console.log(err.description);

        // some additional context, for example the XMLHttpRequest object
        console.log(err.context);
    });

    socket.on('chat', (msg:MessageData) => {
        handleFunction(msg, selectedChatId);
    });

    socket.on('channels', (msg:any) => {
        console.log('Channels event received!', msg);
        setUpdateChannels(true);
    });

    socket.on('participant', (msg:User[]) => {
        console.log('Participant event received!');
        setConectedUsers(msg);
    })


}
export const disconnectSocket = () => {
    console.log('Disconnecting socket...');
    if(socket) {
        socket.disconnect();
        socket = undefined;
    }
}

export const hasSocket = () => {
    console.log('--> Checking socket...', socket, socket !== undefined)
    return socket !== undefined;
}

export const sendMessage = (user:User, chat_id:number, message:string) => {
    var date = new Date();
    var timestamp = Math.floor(date.getTime()/1000);


    const chatmsg = {
        user: user,
        timestamp: timestamp,
        message: {
            message: message,
            chat_id: chat_id
        }
    };

    if (socket){
        const msgJSON = JSON.stringify(chatmsg);
        console.log('sending...', msgJSON);
        socket.emit('chat', msgJSON);

    }
}

export const setSelectedChatID = (chat_id:number) => {
    selectedChatId = chat_id;
}

/*
export const subscribeToChat = (cb) => {
    if (!socket) return(true);
    socket.on('chat', msg => {
        console.log('Websocket event received!');
        return cb(null, msg);
    });
}



 */

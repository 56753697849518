import React from "react";
import {Button, Col, Form, Row, Stack} from "react-bootstrap";
import {User} from "../Actions/Domain";
import {Channel} from "../Actions/Chat/ChatDomain";
import {sendMessage} from "../Actions/Chat/ChatActions";
import {useMutation} from "react-fetching-library";

interface ViewProps {
    token: string;
    user?: User,
    selectedChat: Channel|undefined;
}
export const NewMessages:React.FunctionComponent<ViewProps> = (props) => {
    const [inputValue, setInputValue] = React.useState<string>('');
    const [files, setFiles] = React.useState<File[]>([])
    const {
        user,
        selectedChat,
        token
    } = props;

    let sendMessageAction = useMutation(sendMessage)

    const isDisabled = React.useMemo(() => {
        return sendMessageAction.loading || user === undefined || selectedChat === undefined ;
    }, [sendMessageAction.loading, user, selectedChat])

    const ref = React.useRef<HTMLInputElement>(null);

    const handleSendMessage = React.useCallback(() => {
        if (token === undefined || selectedChat === undefined) return;

        sendMessageAction.mutate({
            token: token,
            chat_id: selectedChat.chat_id,
            mensaje: inputValue,
            files: files
        })
            .then( ({payload}) => {
                if (payload) {
                    if(!payload.error["has-error"]){
                        setInputValue('');
                        setFiles([]);

                        ref.current!.value = "";
                    }
                }
            })
    },[token, selectedChat, sendMessageAction, inputValue])

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Form.Group
                        controlId="formFileMultiple"
                        className="mb-3"
                        onChange={ e => {
                            e.preventDefault()
                            // @ts-ignore
                            const fileList:FileList = e.target.files;
                            const filesInput = Array.from(fileList)

                            console.log(filesInput)
                            setFiles(filesInput)
                        } }
                    >
                        <Form.Label>Multiple files input example</Form.Label>
                        <Form.Control type="file" multiple ref={ref}/>
                    </Form.Group>
                </Col>
            </Row>
            <Stack direction={'horizontal'} gap={3}>
                <Form.Control
                    className="me-auto"
                    placeholder="Agregar Mensaje..."
                    as={'textarea'}
                    disabled={isDisabled}
                    rows={5}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}
                />
                <Button variant="secondary"
                        disabled={isDisabled || inputValue.length === 0 }
                        onClick={handleSendMessage}
                >
                    {
                        sendMessageAction.loading
                            ? "Enviando..."
                            : "Enviar"
                    }
                </Button>

            </Stack>
        </React.Fragment>
    )
}

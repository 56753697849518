import {ServicesUrl} from "../../Config/UrlRuotes";
import {Action} from "react-fetching-library";
import {
    ChatGroupsResponse,
    ChatMessagesResponse, GetChannelResponse,
    MessageResponse, NewChannelResponse,
    UsersResponse,
    ValidateLoginResponse
} from "./ChatDomain";

export const validateToken = (  token:string ) : Action<ValidateLoginResponse> => ({
    method: 'GET',
    endpoint: `${ ServicesUrl.login }/${ token }`,
});

export const getGroups = (  token:string ) : Action<ChatGroupsResponse> => ({
    method: 'GET',
    endpoint: ServicesUrl.chat,
    headers: {
        "Authorization-Token": token
    }
});

export const getMessages = (
    data:{
        token:string, chat_id:number, page: number | undefined, limit : number | undefined
    }
) : Action<ChatMessagesResponse> => ({
    method: 'GET',
    endpoint: `${ServicesUrl.chat}/${data.chat_id}?page=${ data.page ? data.page : 1 }&limit=${data.limit ? data.limit : 10}`,
    headers: {
        "Authorization-Token": data.token
    }
});

export const sendMessage = (
    data:{
        token:string, chat_id: number, mensaje : string, files: File[]
    }
) : Action<MessageResponse> => {
    if (data.files.length === 0) {
        return ({
            method: 'POST',
            endpoint: `${ServicesUrl.message}`,
            body: {
                chat_id: data.chat_id,
                mensaje: data.mensaje
            },
            headers: {
                "Authorization-Token": data.token
            }
        })
    }else{
        const formData = new FormData();
        formData.append('chat_id', data.chat_id.toString());
        formData.append('mensaje', data.mensaje);
        // formData.append('blob', new Blob(['Hello World!\n']), 'test')

        data.files.forEach( file => {
            formData.append('archivos', file);
        });
        return ({
            method: 'POST',
            endpoint: `${ServicesUrl.message}`,
            body: formData,
            headers: {
                "Authorization-Token": data.token,
                // "Content-Type": "multipart/multipart-form-data"
            }
        })

    }
};

export const getUsers = (
    data:{
        token:string, page: number | undefined, limit : number | undefined
    }
) : Action<UsersResponse> => ({
    method: 'GET',
    endpoint: `${ServicesUrl.users}?page=${ data.page ? data.page : 1 }&limit=${data.limit ? data.limit : 10}`,
    headers: {
        "Authorization-Token": data.token
    }
});

export const createChannel = (
    data:{
        token:string, user_id: number[], chat_name: string
    }
) : Action<NewChannelResponse> => ({
    method: 'POST',
    endpoint: `${ServicesUrl.channels}`,
    body: {
        destinatarios: data.user_id,
        nombre: data.chat_name
    },
    headers: {
        "Authorization-Token": data.token
    }
});

export const deleteChannel = (
    data:{
        token:string, chat_id: number
    }
) : Action<NewChannelResponse> => ({
    method: 'DELETE',
    endpoint: `${ServicesUrl.channels}/${data.chat_id}`,
    headers: {
        "Authorization-Token": data.token
    }
});

export const getChannel = (
    data:{
        token:string, chat_id: number
    }
) : Action<GetChannelResponse> => ({
    method: 'GET',
    endpoint: `${ServicesUrl.channels}/${data.chat_id}`,
    headers: {
        "Authorization-Token": data.token
    }
});
